import { createReducer } from "@/utils/store"
import {
  SET_OPTION_STEP_BY_NUMBER,
  RESET_OPTION_STEP_BY_NUMBER,
  SET_AUTH_BY_NUMBER,
  SET_SIGN_IN_INFO,
  CHANGE_SIGN_IN_FETCHING,
  SET_AUTH_STEP_TWO_IS_ERROR,
  SET_USER_PAY_CARD,
  SET_REPEAT_SEND_CODE_TIME,
  SET_AUTH_PASSPORT_COOKIE,
  RESET_SIGN_IN_MODEL
} from "@/store/user/auth/actions"

const defaultValue = {
  isAuth: false,
  fetch: false,
  isErrorCode: false,
  repeatSendCodeTime: 60,
  signInInfo: {
    phone: '',
    fetch: false,
    fetchPending: false,
    secret_key: '',
    title: '',
    avatar: '',
    fullData: false,
    personal_data: {
      passport: {
        check_sum: null
      }
    }
  },
  passportCookie: {
    encData: null,
    checkSum: null
  },
  payCard: {
    type: '',
    number: '',
    fetch: false
  },
  optionStepByNumber: {
    step: 'phone',
    callBack: null,
    stepsName: {
      phone: 'phone',
      phoneCode: 'phoneCode',
      promoCode: 'promoCode',
      promoCodeSuccess: 'promoCodeSuccess'
    }
  }
}

export default createReducer(defaultValue, {
  [ SET_OPTION_STEP_BY_NUMBER ]: (state, { data }) => ({
    ...state,
    optionStepByNumber: { ...state.optionStepByNumber, ...data }
  }),
  [ RESET_OPTION_STEP_BY_NUMBER ]: (state) => ({
    ...state,
    optionStepByNumber: JSON.parse(JSON.stringify(defaultValue.optionStepByNumber))
  }),
  // [ SET_AUTH_BY_NUMBER ]: (state, { status }) => ({ ...state, isAuth: status }),
  [ SET_AUTH_BY_NUMBER ]: (state, { status }) => ({ ...state, isAuth: false }),
  [ RESET_SIGN_IN_MODEL ]: () => (JSON.parse(JSON.stringify(defaultValue))),
  [ CHANGE_SIGN_IN_FETCHING ]: (state, { status }) => ({ ...state, fetch: status }),
  [ SET_SIGN_IN_INFO ]: (state, { info }) => ({ ...state, signInInfo: { ...state.signInInfo, ...info } }),
  [ SET_AUTH_STEP_TWO_IS_ERROR ]: (state, { status }) => ({ ...state, isErrorCode: status }),
  [ SET_USER_PAY_CARD ]: (state, { payCard }) => ({ ...state, payCard }),
  [ SET_REPEAT_SEND_CODE_TIME ]: (state, { time }) => ({ ...state, repeatSendCodeTime: time }),
  [ SET_AUTH_PASSPORT_COOKIE ]: (state, { passport }) => ({
    ...state,
    passportCookie: passport
  }),
})
