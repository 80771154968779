// in dev
import { createParamsApi } from "../../api"
import { fetcher } from "@/utils/fetcher"

export const SET_COMMENT = 'SET_COMMENT'
export const SET_COMMENT_DATA = 'SET_COMMENT_DATA'
export const CHANGE_COMMENT_FETCHING = 'CHANGE_COMMENT_FETCHING'

export const getComment = ({ set, get, apiUrlParams } = {}) => async (dispatch, getState) => {
  dispatch({ type: CHANGE_COMMENT_FETCHING, status: true })
  let api = createParams({ get, apiUrlParams: apiUrlParams })(dispatch, getState)
  let data = await fetcher(api)
  set({ data })(dispatch, getState)
  dispatch({ type: CHANGE_COMMENT_FETCHING, status: true })
}

export const setComment = ({ data }) => (dispatch, getState) => {
  const { opinions, opinions_count } = data
  dispatch({ type: SET_COMMENT_DATA, data: { commentList: opinions, totalComment: opinions_count } })
}

export const createParams = (params) => (dispatch, getState) => {
  const { pagination } = getState().comment
  const { showRows, currentPage } = pagination
  const { get, apiUrlParams } = params
  let api = createParamsApi({ section: 'comment', method: get, apiUrlParams, errorMessage: 'getComment' })
  api.params = {
    start_position: (currentPage - 1) * showRows,
    limit: showRows
  }
  return api
}
