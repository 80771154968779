import { createReducer } from "@/utils/store"
import {
  SET_CLUBS_ACTIVE_RADIO_ID,
  SET_CLUBS_SETTINGS,
  CHANGE_SELECTED_SETTINGS,
  RESET_CLUBS_SELECTED_SETTINGS,
  SET_CLUBS_CURRENT_PAGE,
} from "@/store/clubs/filters/global/actions"

const initial = {
  activeRadioId: 0,
  subways: [],
  services: [],
  options: [],
  nets: [],
  price: {
    min: 1,
    max: 300
  },
  selected: {
    subways: {},
    services: {},
    options: {},
    nets: {},
    geo: {
      // lat: 55.646589,
      // lng: 37.71548
      lat: '',
      lng: ''
    },
    price: {
      min: '',
      max: '',
    },
  },
  pagination: {
    currentPage: 1,
    maxRows: 20
  },
  selectedMethodsTypes: {
    removeAll: 'removeAll',
    price: 'price',
    geo: 'geo'
  }
}

export default createReducer(initial, {
  [ SET_CLUBS_ACTIVE_RADIO_ID ]: (state, { activeRadioId }) => ({ ...state, activeRadioId }),
  [ SET_CLUBS_SETTINGS ]: (state, { settings }) => ({ ...state, ...settings }),
  [ CHANGE_SELECTED_SETTINGS ]: (state, { data }) => ({ ...state, selected: { ...state.selected, ...data } }),
  [ RESET_CLUBS_SELECTED_SETTINGS ]: (state) => ({
    ...state,
    selected: JSON.parse(JSON.stringify(initial.selected)),
  }),
  [ SET_CLUBS_CURRENT_PAGE ]: (state, { currentPage }) => ({
    ...state,
    pagination: { ...state.pagination, currentPage }
  })
})
