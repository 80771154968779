import { createReducer } from "../../../utils/store"

import {
  SET_ADVICE_CLUB_FETCH
} from "./actions"

const initial = {
  fetch: false,
}

export default createReducer(initial, {
  [ SET_ADVICE_CLUB_FETCH ]: (state, { status }) => ({ ...state, fetch: status }),
})
