import styles from './Logo.module.scss'
// import LogoSvg from '../../../assets/icons/logo.svg'
import LogoSvg from '../../../assets/icons/uPassLogo2.svg'
import style from './Logo.module.scss'

export const Logo = () => {
  return (
    // <img className={ styles.root } src={ logo } alt="Логотип"/>
    <LogoSvg className={ styles.root }/>
  )
}
