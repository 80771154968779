import styles from './Download.module.scss'
import { connect } from 'react-redux'
import BtnStore from "../../BtnStore/BtnStore"
import QrImage from './desk.png'
import MobImage from './phone.webp'
import Tablet from './desk.png'

const Download = ({ windowInnerWidth, btnStoreList = [], QrLogo }) => {
  return (
    <div className={ styles.root }>
      <div className={ styles.store }>
        <div className={ styles.storeTitle }>Загрузите приложение и начните свою первую тренировку</div>
        <div className={ styles.storeContainer }>
          { btnStoreList.map(item =>
            <BtnStore btnName={ item.key } links={ item.links } key={ item.key + 'download' }
                      className={ styles.storeItem }/>
          ) }
        </div>
      </div>
      <div className={ styles.qr }>
        <div className={ styles.storeContainerQr }>
          { btnStoreList.map(item =>
            <BtnStore btnName={ item.key } links={ item.links } key={ item.key + 'download' }
                      className={ styles.storeItem }/>
          ) }
        </div>
        <div className={ styles.qrContainer }>
          <div className={ styles.qrimage }>
            { windowInnerWidth > 700 ?
              <div className={ styles.qrCode }>
                { QrLogo }
              </div> : null }
            <img src={ windowInnerWidth <= 700 ? MobImage : (windowInnerWidth <= 950 ? Tablet : QrImage) } alt='QR code'
                 className={ styles.qrimage }/>
          </div>
          <div className={ styles.help }>
            Наведи камеру на QR-код, чтобы скачать
          </div>
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = state => ({
  windowInnerWidth: state.window.size.windowInnerWidth
})

export default connect(mapStateToProps)(Download)
