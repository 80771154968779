import { combineReducers } from "redux"
import auth from './auth/reducer'
import order from './order/reducer'
import trainings from './trainings/reducer'

export default combineReducers({
  auth,
  order,
  trainings
})
