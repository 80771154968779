import { createReducer } from "../../../utils/store"

import { SET_SHOW_MORE_OTHER_CLUBS } from "./actions"

const initial = {
  show: 2
}

export default createReducer(initial, {
  [ SET_SHOW_MORE_OTHER_CLUBS ]: (state, { show }) => ({ ...state, show }),
})
