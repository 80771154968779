import styles from './Support.module.scss'
import { useGetEmail } from "../../../hooks/global/useGetEmail"

const Support = ({ contacts = {} }) => {
  const { phone } = contacts
  const { email } = useGetEmail({ _email: contacts.email })

  return (
    <div className={ styles.root }>
      <a href={ "tel:" + phone } className={ 'title-h3' }>{ phone }</a>
      <a href={ "mailto:" + email } className={ 'title-h3' }>{ email }</a>
    </div>
  )
}

export default Support
