import { memo } from 'react'
import { connect } from 'react-redux'
import Link from "next/link"
import { withRouter } from "next/router"
import styles from './LinkList.module.scss'

const LinkList = memo(({ router, leftMenuIsOpen, leftSideBar, Images, onClick, hideActiveLine, authInfo }) => {
  const { pathname } = router
  let activeElementIndex = null
  leftSideBar.filter(item => item.isAuth && authInfo.isAuth || !item.isAuth).forEach((item, index) => {
    if (item.href === pathname + '/') activeElementIndex = index
  })
  const handlers = {}
  if (onClick) handlers.onClick = onClick

  const topStyle = activeElementIndex * 52
  return (
    <div className={ styles.root + (leftMenuIsOpen ? ' ' + styles.isOpen : '') }>
      <div className={ styles.container }>
        { leftSideBar.map((item, index) =>
          item.isAuth && authInfo.isAuth || !item.isAuth ?
            <div className={ styles.item + (pathname + '/' === item.href ? ' ' + styles.active : '') } key={ index }>
              { !item.href ?
                <span className={ styles.title } { ...handlers }>
                { Images[ item.icon ] }
                  <span>{ item.title }</span>
              </span> : item.isNativeLink ?
                  <a className={ styles.title } href={ item.href } target='_blank' rel="noopener noreferrer">
                    { Images[ item.icon ] }
                    <span>{ item.title }</span>
                    { item.node || null }
                  </a> :
                  <Link as={ item.as } href={ item.href }>
                    <a className={ styles.title }  { ...handlers }>
                      { Images[ item.icon ] }
                      <span>{ item.title }</span>
                      { item.node || null }
                    </a>
                  </Link> }
            </div> : null
        ) }
        { hideActiveLine ? null : <div className={ styles.activeLine }
                                       style={ typeof activeElementIndex !== 'object' ? { top: topStyle + 'px' } : { opacity: 0 } }/> }
      </div>
    </div>
  )
})

const mapStateToProps = state => ({
  leftMenuIsOpen: state.global.leftMenuIsOpen
})

export default withRouter(connect(mapStateToProps)(LinkList))
