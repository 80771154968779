import { createReducer } from "@/utils/store"
import { CHANGE_LEFT_CLUBS_BLOCK } from "@/store/clubs/leftBlock/actions"

const initial = {
  isOpen: true,
  mapCardIsOpen: false,
  name: 'ClubsList/MapClubListRoot/MapClubListRoot.js',
  specificFilterData: {},
  blockNames: {
    clubList: 'ClubsList/MapClubListRoot/MapClubListRoot.js',
    searchList: 'TopSideBar/Search/SearchList/SearchList.js',
    filters: 'Filters/Filters.js',
    specificFilter: 'Filters/SpecificFilter/SpecificFilter.js',
  }
}

export default createReducer(initial, {
  [ CHANGE_LEFT_CLUBS_BLOCK ]: (state, { leftBlock }) => ({
    ...state,
    ...leftBlock
  })
})
