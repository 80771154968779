import { createReducer } from "@/utils/store"

import { SET_COMMENT, CHANGE_COMMENT_FETCHING, SET_COMMENT_DATA } from "./actions"

const initial = {
  fetch: true,
  commentList: [],
  total_rate: '',
  totalComment: null,
  pagination: {
    currentPage: 1,
    showRows: 20
  }
}

export default createReducer(initial, {
  [ CHANGE_COMMENT_FETCHING ]: (state, { status }) => ({ ...state, fetch: status }),
  [ SET_COMMENT ]: (state, { commentList }) => ({ ...state, commentList }),
  [ SET_COMMENT_DATA ]: (state, { data }) => ({ ...state, ...data }),
})
