import { createReducer } from "@/utils/store"

import { SET_POPULAR_COMMENTS } from "./actions"

const initial = {
  list: []
}

export default createReducer(initial, {
  [ SET_POPULAR_COMMENTS ]: (state, { list }) => ({ ...state, list })
})
