import { combineReducers } from "redux"

import city from './city/reducer'
import filters from './filters/reducer'
import global from './global/reducer'
import leftBlock from './leftBlock/reducer'
import map from './map/reducer'

export default combineReducers({
  city,
  filters,
  global,
  leftBlock,
  map
})
