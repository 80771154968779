import { createReducer } from "@/utils/store"
import { SET_CLUBS_CITIES } from "@/store/clubs/city/actions"

const initial = {
  list: []
}

export default createReducer(initial, {
  [ SET_CLUBS_CITIES ]: (state, { cities }) => ({ ...state, list: cities })
})
