import stylesList from '../SearchList.module.scss'
import styles from './History.module.scss'
import { connect } from "react-redux"
import Time from '../../../../../../assets/icons/club/time.svg'
import { searchClubs } from "@/store/clubs/filters/search/actions"

const SearchList = ({ search, history, searchClubs }) => {
  console.log({ history })

  return (
    search.length === 0 ?
      <>
        <div className={ styles.title + ' body3 margin-v-4' }>
          Недавние поисковые запросы
        </div>
        { Object.keys(history).map((key, index) => {
          const item = history[ key ]

          return (
            <div key={ index }
                 onClick={ searchClubs.bind(null, item, false, true) }
                 className={ stylesList.item + ' margin-v-4' }>
              <div className={ styles.icon }>
                <Time/>
              </div>
              <div className={ 'body2' }>
                { item }
              </div>
            </div>
          )
        }) }
      </> : null
  )
}

//refact
const mapStateToProps = state => ({
  search: state.clubs.filters.search.value,
  history: state.clubs.filters.search.history,
})

const mapDispatchToProps = dispatch => ({
  searchClubs: (value, dontSearch, withFilters) => dispatch(searchClubs(value, dontSearch, withFilters))
})

export default connect(mapStateToProps, mapDispatchToProps)(SearchList)
