import { createReducer } from "@/utils/store"
import {
  SET_CLUBS_CENTER_MAP,
  SET_CLUBS_FOR_MAP,
  SET_CLUBS_BOUNDS,
  CHANGE_CLUBS_MAP_FETCHING, RESET_CLUBS_CENTER_MAP
} from "@/store/clubs/map/actions"

const initial = {
  centerMap: {
    lat: '',
    lng: '',
    id: ''
  },
  bounds: null,
  mapClubs: {},
  fetchMapClubs: true
}

export default createReducer(initial, {
  [ SET_CLUBS_CENTER_MAP ]: (state, { centerMap }) => ({ ...state, centerMap }),
  [ RESET_CLUBS_CENTER_MAP ]: (state) => ({ ...state, centerMap: JSON.parse(JSON.stringify(initial.centerMap)) }),
  [ SET_CLUBS_BOUNDS ]: (state, { bounds }) => ({ ...state, bounds }),
  [ SET_CLUBS_FOR_MAP ]: (state, { clubs }) => ({ ...state, mapClubs: clubs }),
  [ CHANGE_CLUBS_MAP_FETCHING ]: (state, { status }) => ({ ...state, fetchMapClubs: status }),
})
