import { combineReducers } from 'redux'
import user from '../user/reducer'
import window from '../window/reducer'
import global from '../global/reducer'
import popular from '../popular/reducer'
import blog from '../blog/reducer'
import faq from '../faq/reducer'
import club from '../club/reducer'
import clubs from '../clubs/reducer'
import modal from '../modal/reducer'
import comment from '../comment/reducer'
import promo from '../promo/reducer'

const combinedReducer = combineReducers({
  user,
  window,
  global,
  popular,
  blog,
  faq,
  club,
  clubs,
  modal,
  comment,
  promo
})

export default combinedReducer
