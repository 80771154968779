import { createReducer } from "@/utils/store"

import {
  CHANGE_MODAL_OPEN_STATE,
  CHANGE_MODAL_NAME,
  RESET_MODAL_MODEL,
  SET_MODAL_ADDITIONAL,
  RESET_MODAL_ADDITIONAL,
  SET_MODAL_ERROR
} from "./actions"

const initial = {
  isOpen: false,
  modalName: '',
  additionalParams: {
    className: '',
    animationFade: false
  },
  error: {
    message: null,
    code: null
  },
  modalNames: {
    consultation: 'Consultation',
    blogSubscribe: 'BlogSubscribe',
    consultationSend: 'ConsultationSend',
    subscribeSuccess: 'SubscribeSuccess',
    searchCity: 'SearchCity',
    mobileMenu: 'MobileMenu',
    gallery: 'Gallery',
    orderSingleTraining: 'OrderSingleTraining',
    orderClubCard: 'OrderClubCard',
    orderClubCardSuccess: 'OrderClubCardSuccess',
    aboutClubCard: 'AboutClubCard',
    adviseSuccess: 'AdviseSuccess',
    authByNumber: 'AuthByNumber',
    removeOrderedTraining: 'RemoveOrderedTraining',
    removePayCard: 'RemovePayCard',
    doesWrong: 'DoesWrong',
    mobileGlobalSearch: 'MobileGlobalSearch',
    downloadMobApp: 'DownloadMobApp',
  }
}

export default createReducer(initial, {
  [ CHANGE_MODAL_OPEN_STATE ]: (state, { status }) => ({ ...state, isOpen: status }),
  [ SET_MODAL_ERROR ]: (state, { error }) => ({ ...state, error: { ...state.error, ...error } }),
  [ RESET_MODAL_ADDITIONAL ]: (state) => ({
    ...state,
    additionalParams: JSON.parse(JSON.stringify(initial.additionalParams))
  }),
  [ SET_MODAL_ADDITIONAL ]: (state, { additional }) => ({
    ...state,
    additionalParams: { ...state.additionalParams, ...additional }
  }),
  [ CHANGE_MODAL_NAME ]: (state, { name }) => ({ ...state, modalName: name }),
  [ RESET_MODAL_MODEL ]: (state) => ({
    ...JSON.parse(JSON.stringify(initial)),
    additionalParams: state.additionalParams,
    modalName: state.modalName
  })
})
