import { createReducer } from "../../utils/store"

import { SET_FAQ_LIST } from "./actions"

const initial = {
  list: [],
}

export default createReducer(initial, {
  [ SET_FAQ_LIST ]: (state, { list }) => ({ ...state, list }),
})
