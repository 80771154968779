import Link from "next/link"
import styles from './Nav.module.scss'

const Nav = ({ additionalLinks, logo }) => {
  return (
    <div className={ styles.root }>
      <div className={ styles.logo }>
        <Link href={ '/' }>
          <a>
            { logo }
          </a>
        </Link>
      </div>
      <div className={ styles.container }>
        { additionalLinks.map((item, index) =>
          <Link href={ item.href } as={ item.as } key={ index + item.href }>
            <a className={ styles.item + ' body2' }>{ item.title }</a>
          </Link>
        ) }
      </div>
    </div>
  )
}

export default Nav
