export const CHANGE_MODAL_OPEN_STATE = 'CHANGE_MODAL_OPEN_STATE'
export const CHANGE_MODAL_NAME = 'CHANGE_MODAL_NAME'
export const RESET_MODAL_MODEL = 'RESET_MODAL_MODEL'

export const changeModalOpenState = (status) => (dispatch) => {
  dispatch({ type: CHANGE_MODAL_OPEN_STATE, status })
}

export const changeModalName = (name) => (dispatch) => {
  dispatch({ type: CHANGE_MODAL_NAME, name })
}

export const openModal = (modalName) => (dispatch) => {
  changeModalName(modalName)(dispatch)
  changeModalOpenState(true)(dispatch)
}

export const resetModalModel = () => {
  return { type: RESET_MODAL_MODEL }
}
