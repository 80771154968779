import styles from './Terms.module.scss'
import BtnLink from "../BtnLink/BtnLink"

const Terms = ({ termHref }) => {
  return (
    <div className={ styles.root + ' body3' }>
      Нажимая «Подписаться», я соглашаюсь с <BtnLink title={ 'Политикой конфиденциальности' } href={ termHref }
                                                     isNativeLink={ true } target='_blank' className={ styles.link }/>
    </div>
  )
}

export default Terms
