import React, { useEffect } from 'react'
import { withRouter } from "next/router"
import { connect } from 'react-redux'
import { throttle } from "throttle-debounce"
import { useDispatch } from "react-redux"
import { changeLocationOrigin, changeWindowScroll, changeWindowSize } from "@/store/window/actions"
import axios from "axios"
import { openErrorModal } from "@/store/modal/actions"

const Root = ({ children, changeWindowSize, size, changeWindowScroll, router }) => {
  const dispatch = useDispatch()
  const { pathname } = router

  useEffect(() => {
    axios.interceptors.response.use(function (response) {
      return response
    }, function (error) {
      if (error.response?.config?.customData) {
        if (!error.response?.config?.customData.dontShowErrorModal) dispatch(openErrorModal({ err: error }))
      }
      return Promise.reject(error)
    })
  })

  useEffect(() => {
    let app = document.getElementById('App')
    try {
      changeWindowSize()
      let throttleResize = throttle(300, changeResize)
      let throttleScroll = throttle(300, changeWindowScroll)

      window.addEventListener('resize', throttleResize)
      window.addEventListener('scroll', throttleScroll)
      app.addEventListener('scroll', throttleScroll)

      return () => {
        window.removeEventListener('resize', throttleResize)
        window.removeEventListener('scroll', throttleScroll)
        app.removeEventListener('scroll', throttleScroll)
      }

    } catch (err) {
      console.log(err)
    }

  }, [])


  const changeResize = () => {
    console.log(window.innerWidth)
    if (size.windowInnerWidth !== window.innerWidth) {
      changeWindowSize()
    }
  }

  return (
    <div id='App' style={ {
      opacity: size.windowInnerWidth ? 1 : 0,
      height: pathname === '/' ? 'unset' : '100vh'
    } }>
      { children }
    </div>
  )
}

const mapStateToProps = state => {
  return {
    size: state.window.size
  }
}

const mapDispatchToProps = dispatch => ({
  changeWindowSize: () => dispatch(changeWindowSize()),
  changeLocationOrigin: () => dispatch(changeLocationOrigin()),
  changeWindowScroll: () => dispatch(changeWindowScroll()),
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Root))
