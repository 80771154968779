import { connect } from 'react-redux'
import styles from './Footer.module.scss'
import Download from "./Download/Download"
import Subscription from "./Subscription/Subscription"
import Links from "./Links/Links"
import OtherLink from "./OtherLink/OtherLink"
import Nav from "./Nav/Nav"
import Term from "./Term/Term"

const Footer = ({
                  windowInnerWidth,
                  linkList,
                  otherOurService,
                  btnStoreList,
                  additionalLinks,
                  dropDownTitleColor,
                  contacts,
                  social,
                  onSubscribe,
                  logo,
                  onSubscribeSuccess,
                  termHref,
                  QrLogo,
                  hideDownloadBlock,
                  hideSubscription,
                  hideLinkList,
                  id,
                  hideOtherLinkForPhone = true
                }) => {
  return (
    <div className={ styles.root } id={ id || null }>
      { hideDownloadBlock ? null : <Download btnStoreList={ btnStoreList } QrLogo={ QrLogo }/> }
      { hideSubscription ? null :
        <Subscription termHref={ termHref } onSubscribeSuccess={ onSubscribeSuccess } onSubscribe={ onSubscribe }/> }
      <div className={ styles.bgContainer }>
        { hideLinkList ? null : <Links linkList={ linkList } contacts={ contacts } otherOurService={ otherOurService }
                                       dropDownTitleColor={ dropDownTitleColor } social={ social }/> }
        { windowInnerWidth <= 600 && hideOtherLinkForPhone ? null :
          <OtherLink otherOurService={ otherOurService.list }/> }
        <Nav additionalLinks={ additionalLinks } logo={ logo }/>
        <Term/>
      </div>
    </div>
  )
}

const mapStateToProps = state => ({
  windowInnerWidth: state.window.size.windowInnerWidth
})

export default connect(mapStateToProps)(Footer)
