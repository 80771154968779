import { fetcher } from "@/utils/fetcher"
import { createParamsApi } from "../../api"

export const SET_FAQ_LIST = 'SET_FAQ_LIST'

export const getFaq = ({ get, set, errorMessage = 'getFaq ' + get, apiUrlParams }) => async (dispatch, getState) => {
  let api = createApi({ get, errorMessage, apiUrlParams })(dispatch, getState)
  let data = await fetcher(api)
  set({ data })(dispatch, getState)
}

const createApi = ({ get, errorMessage, apiUrlParams }) => (dispatch, getState) => {
  return createParamsApi({ method: get, section: 'faq', errorMessage, apiUrlParams })
}

export const setFaqList = ({ data }) => (dispatch, getState) => {
  data[ 0 ].isActive = true
  dispatch({ type: SET_FAQ_LIST, list: data })
}

export const changeFaqDropDown = (list) => (dispatch, getState) => {
  dispatch({
    type: SET_FAQ_LIST,
    list
  })
}
