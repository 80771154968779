import styles from './SearchClubPreload.module.scss'

const SearchClubPreload = ({ totalItems }) => {
  return (
    <>
      { [ ...new Array(totalItems).keys() ].map((key, index) => {
        return (
          <div className={ styles.item } key={ index }>
            <div className={ styles.title + ' preloadBg' }/>
            <div className={ styles.address + ' preloadBg' }/>
          </div>
        )
      }) }
    </>
  )
}

export default SearchClubPreload
