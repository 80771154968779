import Other from "../../LeftSideBar/Other/Other"
import styles from './OtherLink.module.scss'

const OtherLink = ({ otherOurService }) => {
  return (
    <div className={ styles.root }>
      <div className={ styles.title + ' title-h3' }>Другие услуги</div>
      <div className={ styles.container }>
        <Other list={ otherOurService }/>
      </div>
    </div>
  )
}

export default OtherLink
