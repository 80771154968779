import { createReducer } from "@/utils/store"
import {
  SET_POPULAR_CLUBS_FILTERS
} from "@/store/clubs/filters/popular/actions"

const initial = {
  popularFilters: {
    options: [],
    services: []
  },
}

export default createReducer(initial, {
  [ SET_POPULAR_CLUBS_FILTERS ]: (state, { popular }) => ({
    ...state,
    popularFilters: { ...state.popularFilters, ...popular }
  })
})
