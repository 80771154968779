import styles from './Other.module.scss'

const Other = ({ list }) => {
  return (
    <div className={ styles.root }>
      <div className={ styles.container }>
        { list.map((item, index) => {
          return (
            <a  href={ item.href } className={ styles.item } key={ index } target={ '_blank' }
               rel="noopener noreferrer">
              <div className={ styles.imgBox } style={ { background: item.bg } }>
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fillRule="evenodd" clipRule="evenodd"
                        d="M8.7498 2.3664C11.4469 2.3664 13.6334 4.55286 13.6334 7.24999C13.6334 9.94712 11.4469 12.1336 8.7498 12.1336C6.05267 12.1336 3.86621 9.94712 3.86621 7.24999C3.86621 4.55286 6.05267 2.3664 8.7498 2.3664ZM14.3662 7.24999C14.3662 4.14813 11.8517 1.63358 8.7498 1.63358C5.64794 1.63358 3.13339 4.14813 3.13339 7.24999C3.13339 10.3518 5.64794 12.8664 8.7498 12.8664C11.8517 12.8664 14.3662 10.3518 14.3662 7.24999Z"
                        fill="white"/>
                  <path fillRule="evenodd" clipRule="evenodd"
                        d="M5.29698 10.7035C5.15388 10.5604 4.92188 10.5604 4.77879 10.7035L1.74124 13.7411C1.59815 13.8842 1.59815 14.1162 1.74124 14.2593C1.88434 14.4024 2.11634 14.4024 2.25943 14.2593L5.29698 11.2217C5.44007 11.0786 5.44007 10.8466 5.29698 10.7035Z"
                        fill="white"/>
                  <path
                    d="M8.73222 4.45801L9.36291 6.39909H11.4039L9.7527 7.59874L10.3834 9.53983L8.73222 8.34017L7.08103 9.53983L7.71173 7.59874L6.06055 6.39909H8.10152L8.73222 4.45801Z"
                    fill="white"/>
                </svg>
              </div>
              <span className={ styles.title }>{ item.title }</span>
            </a>
          )
        }) }
      </div>
    </div>
  )
}

export default Other
