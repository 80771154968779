import styles from './Links.module.scss'
import { connect } from 'react-redux'
import Link from "next/link"
import Support from "../Support/Support"
import SocialList from "../SocialList/SocialList"
import DropDownLink from "../../DropDownLink/DropDownLink"

const Links = ({
                 windowInnerWidth,
                 linkList = [],
                 otherOurService = { title: '', list: [] },
                 dropDownTitleColor,
                 contacts,
                 social
               }) => {
  return (
    <div className={ styles.root }>
      <div className={ styles.linkContainer }>
        { windowInnerWidth > 600 ?
          <>
            { linkList.map((item, index) =>
              <div className={ styles.links } key={ index }>
                <div className={ styles.linkTitle + ' title-h3' }>{ item.title }</div>
                { item.list.map((link, linkIndex) =>
                  !link.href ?
                    <span className={ styles.link + ' body2' } key={ linkIndex + index }>
                    { link.title }
                   </span> : link.isNativeLink ?
                    <a className={ styles.link + ' body2' } href={ link.href } target='_blank'
                       rel="noopener noreferrer" key={ linkIndex + index }>
                      { link.title }
                    </a> :
                    <Link href={ link.href } as={ link.as } key={ linkIndex + index }>
                      <a className={ styles.link + ' body2' }>{ link.title }</a>
                    </Link>
                ) }
              </div>
            ) }</> :
          <>
            { [ ...linkList, otherOurService ].map((item, index) =>
              <DropDownLink items={ item.list } titleColor={ dropDownTitleColor } title={ item.title } key={ index }/>
            ) }</>
        }
      </div>
      <div className={ styles.additionalInfo }>
        <Support contacts={ contacts }/>
        <SocialList social={ social }/>
      </div>
    </div>
  )
}

const mapStateToProps = state => ({
  windowInnerWidth: state.window.size.windowInnerWidth
})

export default connect(mapStateToProps)(Links)
