import styles from './MobileSideBar.module.scss'
import { connect } from 'react-redux'
import routes from '../../../routes'
import SearchSignIn from '../../../assets/icons/leftSideBar/search-clubs.svg'
import Toggle from '../../../assets/icons/leftSideBar/toggle.svg'
import Phone from '../../../assets/icons/leftSideBar/phone.svg'
import Link from "next/link"
import { openModal } from "@/store/modal/actions"
import Fitness from '../../../assets/icons/leftSideBar/fitness.svg'
import Profile from '../../../assets/icons/user.svg'
import { useRouter } from "next/router"

const Images = {
  SearchSignIn: <SearchSignIn/>,
  Profile: <Profile/>,
  Phone: <Phone/>,
  Fitness: <Fitness/>
}

const { mobileSideBar } = routes

const MobileSideBar = ({ modalNames, openModal, leftBlock, isAuth, activeTrainingsCount }) => {

  const openMenuHandler = () => {
    openModal(modalNames.mobileMenu)
  }

  const { name, blockNames, isOpen } = leftBlock
  const router = useRouter()

  return (
    <>
      <div className={ styles.root + (name === blockNames.clubList && isOpen ? ' ' + styles.leftBlockIsOpen : '') }>
        { mobileSideBar.map((item, index) => {
          const isActiveItem = item.href === (router.pathname + '/')
          const activeClassName = (isActiveItem ? ' ' + styles.activeItem : '')
          console.log({ isActiveItem }, item.href, router.pathname)
          return (
            !item.href ?
              <span className={ styles.item + activeClassName } key={ index }>
                { Images[ item.icon ] }
                <span>{ item.title }</span>
              </span> : item.isNativeLink ?
                <a className={ styles.item + activeClassName } href={ item.href } target='_blank'
                   rel="noopener noreferrer" key={ index }>
                  { Images[ item.icon ] }
                  <span>{ item.title }</span>
                </a> :
                <Link as={ item.as } href={ item.href } key={ index }>
                  <a className={ styles.item + activeClassName }>
                    { Images[ item.icon ] }
                    <span>{ item.title }</span>
                    { item.href === '/profile/training/' && isAuth && activeTrainingsCount ?
                      <div className={ styles.activeTrainingCount }>
                        { activeTrainingsCount }
                      </div>
                      : null }
                  </a>
                </Link>
          )
        }) }
        <div className={ styles.item + ' ' + styles.rootMenu } onClick={ openMenuHandler }>
          <Toggle className={ styles.iconMenu }/>
          <span>Меню</span>
        </div>
      </div>
    </>
  )
}

//refact
const mapStateToProps = state => ({
  windowInnerWidth: state.window.size.windowInnerWidth,
  modalNames: state.modal.modalNames,
  leftBlock: state.clubs.leftBlock,
  isAuth: state.user.auth.isAuth,
  activeTrainingsCount: state.user.trainings.activeCount
})

const mapDispatchToProps = dispatch => ({
  openModal: (name) => dispatch(openModal(name))
})

export default connect(mapStateToProps, mapDispatchToProps)(MobileSideBar)
