import UserImg from '../../../assets/icons/user.svg'
import styles from './Auth.module.scss'
import { connect } from "react-redux"

const Auth = ({
                leftMenuIsOpen,
                modalNames,
                authInfo = {},
                signIn = () => {},
                signOut = () => {},
                onClickAuthAvatar,
                onClickAuthName,
              }) => {

  return (
    <div
      className={ styles.root + (authInfo.isAuth ? ' ' + styles.rootAuht : '') + (leftMenuIsOpen ? ' ' + styles.isOpen : '') }>
      {
        <div className={ styles.isntAuth }>
            <div className={ styles.enter } onClick={ signIn }>
            <div className={ styles.enterTitle }>Войти</div>
            <div className={ styles.orReg }>или зарегистрироваться</div>
          </div>
        </div>
      }
      <div className={ styles.iconSign } onClick={ signIn }>
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fillRule="evenodd" clipRule="evenodd"
                d="M14.2178 16.4678C13.9249 16.7607 13.4501 16.7607 13.1572 16.4678L9.21967 12.5303C8.92678 12.2374 8.92678 11.7626 9.21967 11.4697L13.1572 7.53217C13.4501 7.23928 13.9249 7.23928 14.2178 7.53217C14.5107 7.82506 14.5107 8.29994 14.2178 8.59283L10.8107 12L14.2178 15.4072C14.5107 15.7001 14.5107 16.1749 14.2178 16.4678Z"
                fill="#333333"/>
          <path fillRule="evenodd" clipRule="evenodd"
                d="M21.002 12C21.002 12.4142 20.6662 12.75 20.252 12.75L9.75195 12.75C9.33774 12.75 9.00195 12.4142 9.00195 12C9.00195 11.5858 9.33774 11.25 9.75195 11.25L20.252 11.25C20.6662 11.25 21.002 11.5858 21.002 12Z"
                fill="#333333"/>
          <path fillRule="evenodd" clipRule="evenodd"
                d="M3.43934 3.43934C3.72064 3.15804 4.10217 3 4.5 3H11.25C11.6642 3 12 3.33579 12 3.75C12 4.16421 11.6642 4.5 11.25 4.5L4.5 4.5L4.5 19.5H11.25C11.6642 19.5 12 19.8358 12 20.25C12 20.6642 11.6642 21 11.25 21H4.5C4.10218 21 3.72065 20.842 3.43934 20.5607C3.15804 20.2794 3 19.8978 3 19.5V4.5C3 4.10217 3.15804 3.72064 3.43934 3.43934Z"
                fill="#333333"/>
        </svg>
      </div>
    </div>
  )
}

const mapStateToProps = state => (
  {
    leftMenuIsOpen: state.global.leftMenuIsOpen,
    modalNames: state.modal.modalNames
  }
)

export default connect(mapStateToProps)(Auth)
