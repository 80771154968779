import { createReducer } from "@/utils/store"
import {
  CHANGE_TRAININGS_FETCH,
  SET_TRAININGS,
  SET_TRAININGS_COUNT,
  CHANGE_TRAININGS_PAGINATION,
  RESET_TRAININGS,
  RESET_TRAININGS_PAGINATION,
  SET_TRAININGS_ACTIVE_COUNT
} from "@/store/user/trainings/actions"

const initial = {
  list: [],
  pagination: {
    limit: 20,
    start: 0
  },
  activeCount: null,
  count: null,
  clubId: null,
  fetch: true,
}

export default createReducer(initial, {
  [ SET_TRAININGS ]: (state, { list }) => ({ ...state, list }),
  [ RESET_TRAININGS ]: (state) => ({ ...state, list: JSON.parse(JSON.stringify(initial.list)) }),
  [ RESET_TRAININGS_PAGINATION ]: (state) => ({ ...state, pagination: JSON.parse(JSON.stringify(initial.pagination)) }),
  [ CHANGE_TRAININGS_FETCH ]: (state, { status }) => ({ ...state, fetch: status }),
  [ SET_TRAININGS_COUNT ]: (state, { count }) => ({ ...state, count }),
  [ SET_TRAININGS_ACTIVE_COUNT ]: (state, { count }) => ({ ...state, activeCount: count }),
  [ CHANGE_TRAININGS_PAGINATION ]: (state, { pagination }) => ({
    ...state,
    pagination: { ...state.pagination, ...pagination }
  }),
})
