import { connect } from 'react-redux'
import styles from './GlobalSearch.module.scss'
import SearchList from "@/components/Clubs/TopSideBar/Search/SearchList/SearchList"
import Search from "@/components/Clubs/TopSideBar/Search/Search"
import { useCallback, useState } from "react"
import { openModal } from "@/store/modal/actions"

const GlobalSearch = ({ windowInnerWidth, sizes, openModal, modalNames }) => {
  const [ showDropDown, setShowDropDown ] = useState(false)

  const clickHandler = ({ status }) => {
    if (windowInnerWidth <= sizes.mob && status) {
      console.log('done')
      openModal({ name: modalNames.mobileGlobalSearch })
    }
    if (!showDropDown) {
      document.body.addEventListener('click', hideDropDown, true)
      setShowDropDown(status !== undefined ? status : !showDropDown)
    }
  }

  const hideDropDown = useCallback(({ target } = {}) => {
    try {
      let root = document.querySelector('.' + styles.root)

      if (!root.contains(target)) {
        document.body.removeEventListener('click', hideDropDown)
        clickHandler({ status: false })
      }
    } catch (err) {

    }
  }, [])

  return (
    <div className={ styles.root }>
      <div className={ styles.searchContainer } onClick={ clickHandler.bind(null, { status: true }) }>
        <Search/>
      </div>
      { showDropDown ?
        <div className={ styles.dropDown }>
          <SearchList
            onClickCallBack={ hideDropDown.bind({ target: { contains: () => {} } }) }/>
        </div> : null }
    </div>
  )
}

const mapStateToProps = state => ({
  windowInnerWidth: state.window.size.windowInnerWidth,
  sizes: state.window.sizes,
  modalNames: state.modal.modalNames
})

const mapDispatchToProps = dispatch => ({
  openModal: ({ name }) => dispatch(openModal(name)),
})

export default connect(mapStateToProps, mapDispatchToProps)(GlobalSearch)
