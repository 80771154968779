import styles from './SearchList.module.scss'
import { connect } from "react-redux"
import SearchClubPreload from "@/layouts/TopSIdeBar/SearchClub/SearchClubPreload/SearchClubPreload"
import { changeLeftClubsBlock } from "@/store/clubs/leftBlock/actions"
import { changeSelectedSettings } from "@/store/clubs/filters/global/actions"
import { getSearchFilter, SET_CLUBS_SEARCH_VALUE, setHistorySearch } from "@/store/clubs/filters/search/actions"
import { useRouter } from "next/router"
import SearchWord from "@/components/Clubs/TopSideBar/Search/SearchList/SearchWord/SearchWord"
import History from "@/components/Clubs/TopSideBar/Search/SearchList/History/History"
import Remove from '../../../../../assets/icons/remove2.svg'
import SelectedFilters from "@/components/Clubs/Filters/SelectedFilters/SelectedFilters"
import { resetModalModel } from "@/store/modal/actions"

const SearchList = ({
                      clubs,
                      fetch,
                      search,
                      filters,
                      changeSelectedSettings,
                      selected,
                      setHistorySearch,
                      resetSearchValue,
                      getSearchFilter,
                      sizes,
                      windowInnerWidth,
                      changeLeftClubsBlock,
                      onClickCallBack,
                      setSearchValue,
                      resetModalModel
                    }) => {
  const { services, price, geo } = selected
  const router = useRouter()
  const hasSelected = Object.keys({ ...selected.subways, ...selected.services, ...selected.options, ...selected.nets }).length > 0 || price.min || geo.lat

  const clickClubHandler = ({ title, id }) => {
    router.push('/club/[slug]', '/club/' + id)
    setSearchValue({ value: title })
    if (onClickCallBack) onClickCallBack()
    if (!isClubsPage && windowInnerWidth <= sizes.mob) {
      changeLeftClubsBlock({ isOpen: false })
      resetModalModel()
    }
  }

  const clickHandler = ({ callback, title }) => {
    setHistorySearch({ title })
    callback()
  }

  const serviceOnClickHandler = (item) => {
    if (!isClubsPage) router.push('/clubs/', '/clubs/')
    resetSearchValue()
    getSearchFilter()
    changeSelectedSettings({
      name: item.type + 's',
      value: item
    })
    if (windowInnerWidth < sizes.mob) changeLeftClubsBlock({ isOpen: false })
    if (!isClubsPage && windowInnerWidth <= sizes.mob) {
      resetModalModel()
    }
  }

  const isClubsPage = router.asPath.indexOf('/clubs/') >= 0

  console.log({ router })

  return (
    <div className={ styles.root }>
      { !isClubsPage || windowInnerWidth <= sizes.mob ? <>
        <div className={ styles.selected }>
          <SelectedFilters/>
        </div>
      </> : null }
      { search.length === 0 && !hasSelected ?
        <History/> :
        <div>
          { fetch ? null : !fetch && Object.keys(filters).length <= 0 ? null :
            <>
              { Object.keys(filters).map((key, index) => {
                const item = filters[ key ]
                const isPicked = services[ item.id ]
                const onClickHandler = clickHandler.bind(null, {
                  callback: serviceOnClickHandler.bind(null, item),
                  title: item.title
                })
                return (
                  <div key={ index }
                       onClick={ onClickHandler }
                       className={ styles.item + ' margin-v-4' + (isPicked ? ' ' + styles.pickedFilter : '') }>
                    <div className={ styles.imgContainer }>
                      <img src={ item.icon }/>
                    </div>
                    <SearchWord search={ search } word={ item.title }/>
                    { isPicked ? <Remove className={ styles.remove }/> : null }
                  </div>
                )
              }) }
            </>
          }
          { fetch ? <SearchClubPreload totalItems={ 6 }/> : !fetch && Object.keys(clubs) <= 0 ?
            <div className={ 'body2' }>Клубы не найдены!</div> :
            <>
              { Object.keys(clubs).map((key, index) => {
                const item = clubs[ key ]
                return (
                  <div className={ styles.item + ' margin-v-4' } key={ index }
                       onClick={ clickHandler.bind(null, {
                         callback: clickClubHandler.bind(null, item),
                         title: item.title
                       }) }
                  >
                    <img src={ item.logo }/>
                    <div>
                      <div className={ 'margin-v-6' }>
                        <SearchWord search={ search } word={ item.title }/>
                      </div>
                      {/*<div className={ styles.title + ' body2 margin-v-6' }>{ item.title }</div>*/ }
                      <div className={ styles.address + ' body3' }>{ item.address }</div>
                    </div>
                  </div>
                )
              }) }
            </> }
        </div> }
    </div>
  )
}
//refact
const mapStateToProps = state => ({
  clubs: state.clubs.filters.search.clubs,
  fetch: state.clubs.global.fetch,
  search: state.clubs.filters.search.value,
  filters: state.clubs.filters.search.filters,
  selected: state.clubs.filters.global.selected,
  windowInnerWidth: state.window.size.windowInnerWidth,
  sizes: state.window.sizes,
})
// refact selected
const mapDispatchToProps = dispatch => ({
  changeSelectedSettings: ({ name, value }) => dispatch(changeSelectedSettings({ data: { name, value } })),
  setHistorySearch: ({ title }) => dispatch(setHistorySearch({ title })),
  resetSearchValue: () => dispatch({ type: SET_CLUBS_SEARCH_VALUE, search: { value: '' } }),
  setSearchValue: ({ value }) => dispatch({ type: SET_CLUBS_SEARCH_VALUE, search: { value } }),
  getSearchFilter: ({ search = '' } = {}) => dispatch(getSearchFilter({ search })),
  changeLeftClubsBlock: (data) => dispatch(changeLeftClubsBlock(data)),
  resetModalModel: () => dispatch(resetModalModel()),
})

export default connect(mapStateToProps, mapDispatchToProps)(SearchList)
