import { connect } from 'react-redux'
import LeftSideBarLayout from "@/gdeluLib/LeftSideBar/LeftSideBar"
import routes from "../../../routes"
import SearchSignIn from '../../../assets/icons/leftSideBar/search-clubs.svg'
import Like from '../../../assets/icons/leftSideBar/like.svg'
import Blog from '../../../assets/icons/leftSideBar/blog.svg'
import Friend from '../../../assets/icons/leftSideBar/friend.svg'
import HousePlus from '../../../assets/icons/leftSideBar/house-plus.svg'
import Support from '../../../assets/icons/leftSideBar/support.svg'
import Phone from '../../../assets/icons/leftSideBar/phone.svg'
import Fitness from '../../../assets/icons/leftSideBar/fitness.svg'
import { Logo } from "@/layouts/Logo/Logo"
import modalStyles from '@/components/Modal/Modal.module.scss'
import { setModalAdditional, openModal } from "@/store/modal/actions"
import { withRouter } from "next/router"
import { logOut } from "@/store/user/auth/actions"
import { resetSignInModel } from "@/store/user/auth/actions"
import stylesDownloadApp from '@/components/Modal/DownloadMobApp/DownloadMobApp.module.scss'
import styles from "@/layouts/MobileSideBar/MobileSideBar.module.scss"

const Images = {
  SearchSignIn: <SearchSignIn/>,
  Like: <Like/>,
  Blog: <Blog/>,
  Friend: <Friend/>,
  HousePlus: <HousePlus/>,
  Support: <Support/>,
  Phone: <Phone/>,
  Fitness: <Fitness/>
}

let { leftSideBar, otherOurService, profile } = routes
const { account } = profile

const LeftSideBar = ({
                       isAuth,
                       modalNames,
                       openModal,
                       setModalAdditional,
                       router,
                       singInInfo,
                       logOut,
                       resetSignInModel,
                       activeTrainingsCount
                     }) => {

  const signIn = () => {
    setModalAdditional({ className: modalStyles.containerFlex })
    // resetSignInModel()
    // openModal(modalNames.authByNumber)
    setModalAdditional({ className: stylesDownloadApp.modal })
    openModal(modalNames.downloadMobApp)
  }

  const authInfo = {
    isAuth,
    number: singInInfo.phone
  }

  leftSideBar = leftSideBar.map(item => {
    if (item.href === '/profile/training/' && isAuth && activeTrainingsCount) {
      item.node = (<div className={ styles.activeTrainingCount }>{ activeTrainingsCount }</div>)
    }
    return item
  })

  const goToProfile = () => router.push(account.href, account.as)

  return (
    <>
      <LeftSideBarLayout authInfo={ authInfo }
                         signIn={ signIn }
                         signOut={ logOut.bind(null, router) }
                         onClickAuthName={ goToProfile }
                         onClickAuthAvatar={ goToProfile }
                         Images={ Images }
                         logo={ <Logo/> }
                         leftSideBar={ leftSideBar }
                         otherLink={ otherOurService }/>
    </>
  )
}

const mapStateToProps = state => ({
  leftMenuIsOpen: state.global.leftMenuIsOpen,
  windowInnerWidth: state.window.size.windowInnerWidth,
  isAuth: state.user.auth.isAuth,
  modalNames: state.modal.modalNames,
  singInInfo: state.user.auth.signInInfo,
  activeTrainingsCount: state.user.trainings.activeCount
})

const mapDispatchToProps = dispatch => ({
  openModal: name => dispatch(openModal(name)),
  setModalAdditional: (additional) => dispatch(setModalAdditional(additional)),
  logOut: (router) => dispatch(logOut(router)),
  resetSignInModel: () => dispatch(resetSignInModel()),
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(LeftSideBar))
