import React, { Fragment } from 'react'
import { Provider } from 'react-redux'
import Router from 'next/router'
import { connect } from 'react-redux'
import dynamic from "next/dynamic"
import { bindActionCreators } from 'redux'
import { checkIsAuth, setPassportCookie } from "@/store/user/auth/actions"
import { changeWindowSize } from "@/store/window/actions"
import { changeLocationOrigin } from "@/store/window/actions"
import App from 'next/app'
import Root from "../components/Root"
import withReduxStore from '../utils/with-redux-store'
import '../gdelu-react-ui/src/assets/style/indents.scss'
import cookies from "next-cookies"
import LeftSideBar from "../components/Layouts/LeftSideBar/LeftSideBar"
import MobileSideBar from "@/layouts/MobileSideBar/MobileSideBar"
import '../assets/style/app.scss'
import { setUserAgent } from "@/store/window/actions"
import { getMeta, setMobileOs, showInfoForNewUser } from "@/store/global/actions"
import Meta from "@/layouts/Meta/Meta"
import axios from 'axios'
import { getUserInfo } from "@/store/user/auth/actions"
import moment from "moment"
import { setActiveTrainingCount, getTrainings } from "@/store/user/trainings/actions"
import '@/gdeluLib/../assets/style/animation.scss'

moment.locale('ru')

const Modal = dynamic(() => import('../components/Modal/Modal'), { ssr: false })
const BtnToTop = dynamic(() => import('@/layouts/BtnToTop/BtnToTop'), { ssr: false })
// import { randomString, initSentry } from "../utils"
import TopSideBar from "../components/Layouts/TopSIdeBar/TopSideBar"
import Footer from "../components/Layouts/Footer/Footer"
import { getGlobal } from "@/store/global/actions"
// import { getAccessProviderService } from "../store/provider/global/store"

// initSentry()

// const FixedConsultationBtn = dynamic(() => import('@/gdeluLib/FixedConsultationBtn/FixedConsultationBtn'), { ssr: false })
const Error = dynamic(() => import('./404'), { ssr: false })
import { openModal, setModalAdditional } from "@/store/modal/actions"
import { setCookie } from "@/utils/cookie"
import routes from "../routes"
// const Error = dynamic(() => import('./_error'), { ssr: false })
// const YMInitializer = process.env.NODE_ENV !== 'development' ? dynamic(() => import('react-yandex-metrika').then(mod => mod.YMInitializer), { ssr: false }) : false

Router.events.on('routeChangeComplete', (path) => {
  document.getElementById('App').scrollTop = 0
  const isPrivateRoute = routes.checkPrivateRoute(Router.pathname)
  if (isPrivateRoute) checkIsAuth({ router: Router })
  try {
    window.Intercom("update")
  } catch (err) {
    console.log(err)
  }
})

class MyApp extends App {
  constructor() {
    super()
    this.state = {
      mounted: false,
      showComagic: true,
      routerAsPath: null
    }
  }

  async componentDidMount() {
    setCookie({ key: 'stats_data', value: window.location.href })
    this.getProfileData()
    this.props.showInfoForNewUser({ router: this.props.router })
    this.setState({ mounted: true, routerAsPath: this.props.router.asPath }, () => {
      console.log(this.state, 'this.state')
    })
  }

  getProfileData({ isAuth } = {}) {
    if (this.props.isAuth || isAuth) this.props.getTrainings({
      get: 'getTrainings',
      set: setActiveTrainingCount,
      id: null
    })
  }


  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.router.asPath !== this.state.routerAsPath && this.state.routerAsPath !== null) {
      this.setState({ routerAsPath: nextProps.router.asPath, showComagic: false }, () => {
        setTimeout(() => {
          this.setState({ showComagic: true })
        }, 200)
      })
      console.log('nextProps = ' + nextProps.router.asPath, 'currentState' + this.state.routerAsPath, 'nextProps')
    }
    if (nextProps.isAuth !== this.props.isAuth && nextProps.isAuth) {
      console.log('this.getProfileData')
      this.getProfileData({ isAuth: true })
    }
  }

  hideImage() {
    this.setState({ mounted: false })
  }

  render() {
    const {
      Component,
      pageProps,
      store,
      host,
      referer,
      router,
      leftMenuIsOpen,
      windowInnerWidth,
      userAgent,
      modalNames,
      openModal
    } = this.props
    console.log(router, 'router')
    const isClubPage = router.pathname.indexOf('/clubs') >= 0
    const hideFooter = router.pathname === '/map' || isClubPage
    console.log(router.pathname, { isClubPage })
    return (
      <Provider store={ store }>
        <Root>
          <>
            <Meta/>
            <div id='root-app'>
              { windowInnerWidth > 600 ? <LeftSideBar/> : <MobileSideBar/> }
              <div id='root-app-container'
                   className={
                     (leftMenuIsOpen ? ' root-app-container-open' : '') +
                     (isClubPage ? ' root-app-container-clubs' : '')
                   }
              >
                { router.pathname !== '/map' && !isClubPage ? <TopSideBar/> : null }
                <main className={
                  (router.pathname !== '/' ? 'is-not-home-main' : '') +
                  (isClubPage ? ' main-clubs' : '') }
                >
                  { pageProps && pageProps.error && pageProps.error.status ? <Error { ...pageProps }/> :
                    <Component { ...pageProps }/> }
                </main>
                { hideFooter ? null : <Footer/> }
              </div>
            </div>
            <Modal/>
            <BtnToTop/>
            {/*<div className={ 'fixedBtn' }>*/ }
            {/*  <FixedConsultationBtn onClick={ () => openModal(modalNames.consultation) }/>*/ }
            {/*</div>*/ }
          </>
        </Root>
      </Provider>
    )
  }
}

MyApp.getInitialProps = async (appContext) => {
  let appProps
  const { ctx } = appContext
  const { store, res, req, asPath, pathname, query, router } = ctx
  const { dispatch, getState } = store
  const isServer = !!ctx.req
  let { jwt } = cookies(ctx)

  axios.defaults.headers.Authorization = 'Token ' + jwt

  try {
    let host = null
    if (isServer) {
      dispatch(setMobileOs({ userAgent: ctx.req.headers[ 'user-agent' ] }))
      dispatch(setPassportCookie({ ctx }))
      if (query.auth && query.auth === 'true') {
        const { modalNames } = getState().modal
        dispatch(setModalAdditional({ className: 'autoContainer' }))
        dispatch(openModal(modalNames.authByNumber))
      }
      dispatch(changeWindowSize({
        width: (ctx.req.headers[ 'user-agent' ]).match(
          /Android|BlackBerry|iPhone|iPad|iPod|Opera Mini|IEMobile|WPDesktop/i
        ) === null ? 1800 : store.getState().window.sizes.mob, height: 300
      }))
      dispatch(setUserAgent(ctx.req.headers[ 'user-agent' ]))

      host = ctx.req.headers.host
      if (host.indexOf(':') > 0 && host !== 'localhost:3000') host = host.slice(0, host.indexOf(':'))
      if (host.indexOf('https://') < 0 && host !== 'localhost:3000') host = 'https://' + host
    }

    if (!!host) dispatch(changeLocationOrigin(host))
    if (isServer || !getState().user.auth.signInInfo.phone) {
      // await dispatch(getUserInfo())
    }
    await dispatch(getUserInfo())
    const isPrivateRoute = routes.checkPrivateRoute(pathname)
    if (isPrivateRoute) {
      let isAccess = dispatch(checkIsAuth({ res, router: Router }))
      if (!isAccess) return {}
    }

    appProps = await App.getInitialProps(appContext)

    const asyncAllMethod = () => {
      let allMethods = [
        dispatch(getGlobal()),
        dispatch(getMeta({ router: { pathname, query } }))
      ]
      // if (isServer) allMethods.push(store.dispatch(getUserInfo()))
      return Promise.all(allMethods)
    }

    if (isServer) {
      await asyncAllMethod()
    }
    else {
      asyncAllMethod()
    }

    if (isServer) {
      if (appProps.pageProps.error && !!appProps.pageProps.error.status) {
        res.statusCode = 404
      }
    }

    return { ...appProps }
  } catch (e) {
    // setSentryMessage('\x1b[31m%s\x1b[0m', e + ' GLOBAL APP ERROR');
    console.error('\x1b[31m%s\x1b[0m', e + ' GLOBAL APP ERROR')

    if (isServer) {
      res.statusCode = 404
    }
    return { pageProps: { error: { status: 404 } } }
  }
}

const mapStateToProps = state => {
  return {
    leftMenuIsOpen: state.global.leftMenuIsOpen,
    windowInnerWidth: state.window.size.windowInnerWidth,
    userAgent: state.window.userAgent,
    modalNames: state.modal.modalNames,
    isAuth: false
  }
}

const mapDispatchToProps = dispatch => ({
  openModal: bindActionCreators(openModal, dispatch),
  getTrainings: bindActionCreators(getTrainings, dispatch),
  showInfoForNewUser: bindActionCreators(showInfoForNewUser, dispatch),
  checkIsAuth: bindActionCreators(showInfoForNewUser, checkIsAuth),
})

export default withReduxStore(connect(mapStateToProps, mapDispatchToProps)(MyApp))
