import { createReducer } from "@/utils/store"
import {
  SET_SINGLE_TRAINING_ORDER_DATA,
  SET_CLUB_CARD_ORDER_DATA,
  SET_REMOVE_SINGLE_TRAINING_ORDER_DATA,
  RESET_REMOVE_SINGLE_TRAINING_ORDER_DATA,
  SET_UKASSA_DATA, RESET_UKASSA_DATA
} from "@/store/user/order/actions"

const initial = {
  ukassa: {
    confirmation_token: ''
  },
  orderSingle: {
    step: 0,
    supportBackCallBack: null,
    oldStep: null,
    fetch: false,
    workoutId: null,
    club_user_info: {
      personal_data_required: true,
      registered: false,
      club_user_data_required: false,
    },
    userData: {
      withoutPassport: true,
      saveLocalPassport: true
    },
    item: {}
  },
  singleStepStatus: {
    chooseDate: 'chooseDate',
    order: 'order',
    showVoucher: 'showVoucher',
    failed: 'failed',
    withBindCard: 'withBindCard',
    details: 'details',
    started: 'started',
    completed: 'completed',
    doesntActive: 'doesntActive',
    support: 'support',
    userData: 'userData',
    cancelOrder: 'cancelOrder'
  },
  removeOrderSingle: {
    step: 0,
    fetch: false,
    item: {}
  },
  orderClubCard: {
    fetch: false,
    item: {}
  }
}

export default createReducer(initial, {
  [ SET_SINGLE_TRAINING_ORDER_DATA ]: (state, { data }) => ({
    ...state,
    orderSingle: { ...state.orderSingle, oldStep: state.orderSingle.step, supportBackCallBack: null, ...data }
  }),
  [ SET_CLUB_CARD_ORDER_DATA ]: (state, { data }) => ({
    ...state,
    orderClubCard: { ...state.orderClubCard, ...data }
  }),
  [ SET_REMOVE_SINGLE_TRAINING_ORDER_DATA ]: (state, { data }) => ({
    ...state,
    removeOrderSingle: { ...state.removeOrderSingle, ...data }
  }),
  [ RESET_REMOVE_SINGLE_TRAINING_ORDER_DATA ]: (state) => ({
    ...state,
    removeOrderSingle: JSON.parse(JSON.stringify(initial.removeOrderSingle))
  }),
  [ SET_UKASSA_DATA ]: (state, { data }) => ({
    ...state,
    ukassa: { ...state.ukassa, ...data }
  }),
  [ RESET_UKASSA_DATA ]: (state) => ({
    ...state,
    ukassa: JSON.parse(JSON.stringify(initial.ukassa))
  }),
})
