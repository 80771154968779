import { combineReducers } from "redux"

import global from './global/reducer'
import popular from './popular/reducer'
import search from './search/reducer'

export default combineReducers({
  global,
  popular,
  search
})
