import { Fragment } from 'react'

const SearchWord = ({ search = '', word }) => {
  let wordReplaceIndex = word.toLowerCase().indexOf(search.toLowerCase())
  let wordReplace = word.slice(wordReplaceIndex, wordReplaceIndex + search.length)
  console.log({ wordReplaceIndex, wordReplace, search: search.length, wor21d: word, word: word.slice(7, 1) })
  return (
    <div className={ 'body2' }>
      {
        <Fragment>
          { word.slice(0, wordReplaceIndex) }
          <span style={ { fontWeight: '600' } }>
                                { wordReplace }
                              </span>
          { word.slice(wordReplaceIndex + wordReplace.length, word.length) } { ' ' }
        </Fragment>
      }
    </div>
  )
}

export default SearchWord
