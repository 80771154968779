import { createReducer } from "../../utils/store"

import { SET_BLOG_LIST, SET_PULSE_CONFIG } from "./actions"

const initial = {
  list: [],
  pulseConfig: {
    //https://sendpulse.ua/ru/integrations/api
    access_token: null,
    token_type: null,
    expires_in: null
  }
}

export default createReducer(initial, {
  [ SET_BLOG_LIST ]: (state, { list }) => ({ ...state, list }),
  [ SET_PULSE_CONFIG ]: (state, { config }) => ({ ...state, pulseConfig: config }),
})
