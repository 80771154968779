import { createReducer } from "../../utils/store"

import {
  SET_DEFAULT_SETTINGS,
  TOGGLE_LEFT_MENU_STATE,
  SET_TERMS_OR_PRIVACY,
  SET_GLOBAL_META,
  SET_USER_MOB_OS
} from "./actions"

const initial = {
  contacts: {
    phone: '',
    email: '',
    address: '',
    app_links: {
      ios: '',
      android: ''
    },
    social: []
  },
  rating_max: '',
  clubs_count: '',
  clubs_greater_than: '',
  leftMenuIsOpen: false,
  privacy: null,
  agreement: null,
  meta: {
    meta_tags: {}
  },
  mobileOs: ''
}

export default createReducer(initial, {
  [ SET_DEFAULT_SETTINGS ]: (state, { settings }) => ({ ...state, ...settings }),
  [ TOGGLE_LEFT_MENU_STATE ]: (state, { status }) => ({ ...state, leftMenuIsOpen: status }),
  [ SET_TERMS_OR_PRIVACY ]: (state, { data }) => ({ ...state, ...data }),
  [ SET_GLOBAL_META ]: (state, { data }) => ({ ...state, meta: data }),
  [ SET_USER_MOB_OS ]: (state, { os }) => ({ ...state, mobileOs: os }),
})
