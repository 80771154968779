import { createReducer } from "@/utils/store"
import { SET_CLUBS_SEARCH_VALUE, RESET_CLUBS_SEARCH_VALUE } from "@/store/clubs/filters/search/actions"

const initial = {
  value: '',
  history: [],
  filters: [],
  clubs: {},
  isSearchFetch: false
}

export default createReducer(initial, {
  [ SET_CLUBS_SEARCH_VALUE ]: (state, { search }) => ({ ...state, ...search }),
  [ RESET_CLUBS_SEARCH_VALUE ]: () => (JSON.parse(JSON.stringify(initial))),
})
