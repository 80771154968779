import { connect } from 'react-redux'
import FooterUi from "@/gdeluLib/Footer/Footer"
import router from '../../../routes'
import { openModal } from "@/store/modal/actions"
import { subscribeToBlog } from "@/store/blog/actions"
import QrLogo from './qr.svg'
import { Logo } from "@/layouts/Logo/Logo"

const { additionalLinks, otherOurService, terms } = router
const { privacy, userAgreement } = terms

const linkList = [
  {
    title: 'Меню',
    list: [
      { title: 'Каталог клубов', as: '/clubs', href: '/clubs', color: 'dark' },
      { title: 'Блог о фитнесе', href: 'https://fitness.gde-luchshe.ru/blog/', isNativeLink: true, color: 'dark' },
      { title: 'Контакты', href: '/contacts', as: '/contacts', color: 'dark' },
    ]
  },
  {
    title: 'Полезные ссылки',
    list: [
      { title: 'Политика обработки персональных данных', as: privacy.as, href: privacy.href, color: 'dark' },
      { title: 'Пользовательское соглашение', as: userAgreement.as, href: userAgreement.href, color: 'dark' },
    ]
  },
  {
    title: 'Партнерство',
    list: [
      { title: 'Посоветовать клуб', as: '/advise/', href: '/advise/', color: 'dark' },
    ]
  },
]

const Footer = ({ windowInnerWidth, contacts, social, openModal, modalNames, subscribeToBlog, appLinks }) => {
  const btnStoreList = [
    {
      key: 'g-play',
      links: {
        android: appLinks.android
      }
    },
    {
      key: 'ios',
      links: {
        ios: appLinks.ios
      }
    },
    {
      links: {
        'app-gallery': appLinks.appgallery
      },
      key: 'app-gallery'
    }
  ]
  const subscribeHandler = async email => {
    await subscribeToBlog(email)
  }

  return (
    <>
      <FooterUi
        btnStoreList={ btnStoreList }
        linkList={ linkList }
        id={'Footer'}
        termHref={ terms.privacy.href }
        additionalLinks={ [] }
        QrLogo={ <QrLogo/> }
        dropDownTitleColor="dark"
        contacts={ contacts }
        logo={ <Logo/> }
        otherOurService={ { title: 'Другие сервисы', list: otherOurService.map(item => ({ ...item, color: 'dark' })) } }
        social={ social }
        onSubscribe={ openModal.bind(null, modalNames.blogSubscribe) }
        onSubscribeSuccess={ email => subscribeHandler(email) }
      />
    </>
  )
}

const mapStateToProps = state => ({
  windowInnerWidth: state.window.size.windowInnerWidth,
  contacts: state.global.contacts.support,
  social: state.global.contacts.social,
  modalNames: state.modal.modalNames,
  appLinks: state.global.contacts.app_links
})

const mapDispatchToProps = dispatch => ({
  openModal: (name) => dispatch(openModal(name)),
  subscribeToBlog: (email) => dispatch(subscribeToBlog(email)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Footer)
