import { useEffect, useState } from 'react'
import styles from './DropDownLink.module.scss'
import BtnLink from "../BtnLink/BtnLink"

const DropDownLink = ({ items, title, titleColor, onChangeShowList, listClass, rootClass, linkOnClickHandler }) => {
  const [ showList, setShowList ] = useState(false)
  let currentEl = null
  console.log(items)

  const hideShowList = () => {
    setShowList(false)
    document.body.removeEventListener('click', hideShowList)
    if (linkOnClickHandler) linkOnClickHandler()
  }

  useEffect(() => {
    if (onChangeShowList) onChangeShowList(showList)
  }, [ showList ])

  const checkHideList = e => {
    if (!currentEl.contains(e.target)) {
      setShowList(false)
      document.body.removeEventListener('click', checkHideList)
    }
  }

  const changeShowList = e => {
    e.stopPropagation()
    console.log(e.currentTarget.parentNode)
    currentEl = e.currentTarget.parentNode
    // setCurrentEl(e.currentTarget.parentNode)
    if (!showList) document.body.addEventListener('click', checkHideList)
    setShowList(!showList)
  }

  return (
    <div className={ styles.root + (rootClass ? ' ' + rootClass : '') + (showList ? ' ' + styles.rootActive : '') }>
      <div className={ styles.title } onClick={ changeShowList }>
        <BtnLink color={ titleColor } className={ 'title-h3' } title={ title }/>
        <svg width="18" height="10" viewBox="0 0 18 10" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fillRule="evenodd" clipRule="evenodd"
                d="M17.0199 9.1881C16.6836 9.52443 16.1383 9.52443 15.8019 9.1881L9.10299 2.48917L2.40407 9.1881C2.06773 9.52443 1.52242 9.52443 1.18608 9.1881C0.84974 8.85176 0.84974 8.30645 1.18608 7.97011L9.10299 0.0531908L17.0199 7.97011C17.3563 8.30645 17.3563 8.85176 17.0199 9.1881Z"
                fill="#333333"/>
        </svg>

      </div>
      <div
        className={ styles.list + (listClass ? " " + listClass : '') + (!showList ? ' ' + styles.hideShowList : '') }>
        { items.map((item, index) =>
          <div key={ index } className={ styles.item + ' body2' }>
            <BtnLink href={ item.href } as={ item.as || item.href } title={ item.title } color={ item.color }
                     isNativeLink={ item.isNativeLink } onClickHandler={ hideShowList }/>
          </div>
        ) }
      </div>
    </div>
  )
}

export default DropDownLink
