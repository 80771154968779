import { connect } from 'react-redux'
import { withRouter } from "next/router"
import LinkList from "../../Home/TopSidebar/LinkList/LinkList"
import styles from './TopSideBar.module.scss'
import Link from "next/link"
import { Logo } from "@/layouts/Logo/Logo"
import { openModal } from "@/store/modal/actions"
import GlobalSearch from "@/layouts/TopSIdeBar/GlobalSearch/GlobalSearch"

const TopSideBar = ({ leftMenuIsOpen, windowInnerWidth, router }) => {
  return (
    <div className={ styles.root + (leftMenuIsOpen ? ' ' + styles.isOpen : '') }>
      <div className={ styles.container }>
        <div className={ styles.logo }>
          <Link href={ '/' }>
            <a>
              <Logo key={ 'key' }/>
            </a>
          </Link>
        </div>
        { windowInnerWidth > 600 ?
          <>
            { router.pathname === '/' ? <LinkList/> : <GlobalSearch/> }
            { router.pathname === '/' ? <div style={ { marginLeft: 'auto' } }/> : null }
          </> : null }
        { windowInnerWidth <= 600 && router.pathname !== '/' ? <GlobalSearch/> : null }
      </div>
    </div>
  )
}

const mapStateToProps = state => {
  return {
    leftMenuIsOpen: state.global.leftMenuIsOpen,
    windowInnerWidth: state.window.size.windowInnerWidth,
  }
}

const mapDispatchToProps = dispatch => ({
  openModal: ({ name }) => dispatch(openModal(name)),
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(TopSideBar))
