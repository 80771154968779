import Head from "next/head"
import fav from '../../../assets/icons/fav.png'
import { connect } from 'react-redux'
import dynamic from "next/dynamic"

const Ukasa = dynamic(() => import('./Ukasa/Ukasa'), { ssr: false })
const Intercom = dynamic(() => import('./Intercom/Intercom'), { ssr: false })
const Metrica = dynamic(() => import('./Metrica/Metrica'), { ssr: false })

const Meta = ({ meta, url }) => {
  return (
    <>
      <Head>
        <meta charSet={ 'utf-8' }/>
        <meta name="viewport" content="width=device-width, initial-scale=1.0, viewport-fit=cover  maximum-scale=1"/>
        <meta name="keywords" content={ meta.keywords }/>
        <meta name="description" content={ meta.description }/>
        <title>{ meta.title }</title>
        <meta property="og:title"
              content={ meta.title }/>
        <meta property="og:description"
              content={ meta.description }/>
        <link rel="manifest" href="/manifest.json"/>
        <link rel="apple-touch-icon" href="/fav180.png"/>
        <meta name="theme-color" content="#1557FF"/>
        <link rel="shortcut icon" href={ fav } type="image/x-icon"/>
        {/*<meta property="og:url"*/ }
        {/*      content={ location + (url || '/') }/>*/ }
        {/*<meta property="og:image"*/ }
        {/*      content={ gdeImg }/>*/ }
        <meta name="twitter:card" content="summary_large_image"/>
        {/*<meta name="twitter:site" content={ location + (url || '/') }/>*/ }
        <meta name="twitter:creator" content="@abc"/>
        <meta name="twitter:title" content={ meta.title }/>
        <meta name="twitter:description" content={ meta.description }/>
        {/*<meta name="twitter:image"*/ }
        {/*      content={ gdeImg }/>*/ }
        <link href="https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700&display=swap"
              rel="preload" as={ 'style' } onLoad="this.onload=null;this.rel='stylesheet'"/>
        <noscript>
          <link rel="stylesheet"
                href="https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700&display=swap"/>
        </noscript>
        {/*<script src="https://yookassa.ru/checkout-widget/v1/checkout-widget.js" defer={ true }/>*/ }
      </Head>
      <Metrica/>
      <Ukasa/>
      <Intercom/>
    </>
  )
}

const mapStateToProps = state => ({
  meta: state.global.meta.meta_tags
})

export default connect(mapStateToProps)(Meta)
