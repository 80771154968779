import styles from './Term.module.scss'

const Term = () => {
  return (
    <div className={ styles.root + ' body3' }>
      Google Play и логотип Google Play являются товарными знаками корпорации Google LLC. Apple, логотип Apple являются
      товарными знаками Apple Inc. Логотип Huawei AppGallery является товарным знаком Huawei Services (Hong Kong) Co.,
      Limited
    </div>
  )
}

export default Term
