import { createReducer } from "../../../utils/store"
import {
  RESET_CLUB_MODEL,
  CHANGE_CLUB_FETCHING,
  SET_CLUB_GLOBAL,
  SET_CLUB_GALLERY_PHOTOS,
  SET_CLUB_CARD_FILTER,
  SET_CLUB_CARD_FILTER_ACTIVE
} from "./actions"

const initial = {
  fetch: true,
  id: '',
  slug: '',
  title: '',
  full_title: '',
  logo: null,
  photos: [],
  rating: '',
  galleryPhotosPosition: null,
  services: [],
  options: [],
  total_rate: '',
  total_opinions: '',
  is_favorite: null,
  workouts: [],
  clubCardFilter: {
    list: [],
    active: null
  },
  club_cards: [],
  about: '',
  address: '',
  site_url: '',
  instagram_url: '',
  subways: [],
  worktimes: []
}

export default createReducer(initial, {
  [ RESET_CLUB_MODEL ]: () => (initial),
  [ CHANGE_CLUB_FETCHING ]: (state, { status }) => ({ ...state, fetch: status }),
  [ SET_CLUB_GLOBAL ]: (state, { info }) => ({ ...state, ...info }),
  [ SET_CLUB_GALLERY_PHOTOS ]: (state, { galleryPhotosPosition }) => ({ ...state, galleryPhotosPosition }),
  [ SET_CLUB_CARD_FILTER ]: (state, { clubCards }) => ({
    ...state,
    clubCardFilter: { ...state.clubCardFilter, list: clubCards }
  }),
  [ SET_CLUB_CARD_FILTER_ACTIVE ]: (state, { active }) => ({
    ...state,
    clubCardFilter: { ...state.clubCardFilter, active }
  }),
})
