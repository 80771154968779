import { connect } from 'react-redux'
import React from 'react'
import PropTypes from 'prop-types'
import BtnLink from "../BtnLink/BtnLink"
import styles from './BtnStore.module.scss'

const BtnStore = ({ linksDefault, btnName, className, links = linksDefault }) => {
  const frontKeyToLink = {
    'g-play': 'android',
    'ios': 'ios',
    'app-gallery': 'app-gallery',
  }
  return (
    <BtnLink isNativeLink={ true } target={ '_blank' } href={ links[ frontKeyToLink[ btnName ] ] }
             className={ styles.link + (className ? ' ' + className : '') }
             title={ <div className={ styles.root + ' ' + styles[ btnName ] }/> }>
    </BtnLink>
  )
}

BtnStore.propTypes = {
  btnName: PropTypes.oneOf([ 'g-play', 'ios', 'app-gallery' ]),
  className: PropTypes.string,
}

const mapStateToProps = state => ({
  linksDefault: state.global.contacts.app_links
})

export default connect(mapStateToProps)(BtnStore)
