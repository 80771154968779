import { fetcher } from "@/utils/fetcher"
import { createParamsApi } from "../../api"
import { openModal } from "@/store/modal/actions"

export const SET_BLOG_LIST = 'SET_BLOG_LIST'
export const SET_PULSE_CONFIG = 'SET_PULSE_CONFIG'

export const getBlog = ({ get, set, errorMessage = 'getBlog ' + get, additionalParams }) => async (dispatch, getState) => {
  let api = createParamsApi({ method: get, section: 'blog', errorMessage })
  let data = await fetcher(api)
  set({ data })(dispatch, getState)
}

export const setBlogList = ({ data }) => (dispatch, getState) => {
  dispatch({ type: SET_BLOG_LIST, list: data })
}

export const subscribeToBlog = (email) => async (dispatch, getState) => {
  const { modalNames } = getState().modal
  const api = createParamsApi({
    method: 'subscribeToBlog',
    section: 'blog',
    params: { email, type: "discounts" }
  })
  let res = fetcher(api)
  openModal(modalNames.subscribeSuccess)(dispatch, getState)
  console.log(res)
}

