import styles from './Subscription.module.scss'
import { connect } from 'react-redux'
import Terms from "../../Terms/Terms"
import Input from "../../Input/Input"
import Btn from "../../Btn/Btn"
import { useState } from 'react'
import { openModal } from "../../../store/modal/actions"

const Subscription = ({ modalNames, openModal, windowInnerWidth, onSubscribe = () => {}, onSubscribeSuccess = () => {}, termHref }) => {
  const [ isSend, setIsSend ] = useState(false)

  const clickHandler = () => {
    if (windowInnerWidth <= 600) {
      onSubscribe()
    }
    else {
      setIsSend(false)
      setTimeout(() => {
        setIsSend(true)
      }, 300)
    }
  }

  return (
    <div className={ styles.root }>
      <div className={ styles.info }>
        <svg width="56" height="61" viewBox="0 0 56 61" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M32.1641 48.8223C32.1641 48.7424 32.1655 48.6628 32.1671 48.5832H6.5C6.24148 48.5832 6.03125 48.373 6.03125 48.1145V23.5684H2.16406C1.51684 23.5684 0.992188 24.093 0.992188 24.7402V42.1836C2.7718 46.6958 5.66234 50.6478 9.32539 53.7054H33.2615C32.5583 52.2239 32.1641 50.5684 32.1641 48.8223Z"
            fill="#E8B800"/>
          <rect x="1" y="22.3652" width="33" height="32" rx="2" fill="#E8B800"/>
          <path
            d="M23.1511 32.0097L27.3832 27.8714C27.4712 27.7853 27.5877 27.7378 27.7108 27.7378C27.8341 27.7378 27.9505 27.7853 28.0385 27.8714L32.2706 32.0097L49.2107 18.9404L49.5077 18.5633L28.0886 0.503223C27.8704 0.319238 27.5514 0.319238 27.3332 0.503223L5.91406 18.5633L6.21102 18.9404L23.1511 32.0097Z"
            fill="#FED402"/>
          <path
            d="M23.4688 8.80209L30.7006 2.70436L28.09 0.503223C27.8718 0.319238 27.5529 0.319238 27.3347 0.503223L17.4922 8.80209H23.4688Z"
            fill="#FAC600"/>
          <path d="M11.5579 13.8047L5.91406 18.5634L11.5579 22.9177V13.8047Z" fill="#FAC600"/>
          <path
            d="M23.1517 32.0093L27.3839 27.8711C27.472 27.785 27.5884 27.7375 27.7116 27.7375C27.8349 27.7375 27.9512 27.785 28.0393 27.8711L32.2715 32.0093L43.8646 23.0652V9.97363C43.8646 9.32641 43.34 8.80176 42.6927 8.80176H12.7305C12.0832 8.80176 11.5586 9.32641 11.5586 9.97363V23.0652L23.1517 32.0093Z"
            fill="white"/>
          <path
            d="M16.4616 26.6999V9.97363C16.4616 9.32641 16.9862 8.80176 17.6335 8.80176H12.7305C12.0832 8.80176 11.5586 9.32641 11.5586 9.97363V22.9172L16.4616 26.6999Z"
            fill="#EAF6FF"/>
          <path
            d="M43.5898 37.3966C45.7547 37.3966 47.7807 38.0017 49.5078 39.0517V18.5635L27.7109 35.3797L5.91406 18.5635V48.1145C5.91406 48.4381 6.17645 48.7005 6.5 48.7005H32.1656C32.2313 42.4563 37.3304 37.3966 43.5898 37.3966Z"
            fill="#FEE45A"/>
          <path
            d="M10.7188 48.1145V22.2702L5.91406 18.5635V48.1145C5.91406 48.4381 6.17645 48.7005 6.5 48.7005H11.3047C10.9811 48.7005 10.7188 48.4381 10.7188 48.1145Z"
            fill="#FEDF30"/>
          <path
            d="M38.9537 38.3808L28.1207 27.7881C27.893 27.5654 27.5291 27.5654 27.3014 27.7881L6.08789 48.5309C6.19383 48.6357 6.33926 48.7008 6.50016 48.7008H32.1657C32.214 44.1022 34.9922 40.1466 38.9537 38.3808Z"
            fill="#FED402"/>
          <path
            d="M30.1719 29.7939L28.1207 27.7881C27.893 27.5654 27.5291 27.5654 27.3014 27.7881L6.08789 48.5309C6.19383 48.6357 6.33926 48.7008 6.50016 48.7008H11.422C11.2613 48.7008 11.1157 48.6359 11.0098 48.5309L30.1719 29.7939Z"
            fill="#FAC600"/>
          <path
            d="M34.7048 16.4151H20.7168C20.2314 16.4151 19.8379 16.0215 19.8379 15.5361C19.8379 15.0507 20.2314 14.6572 20.7168 14.6572H34.7047C35.19 14.6572 35.5836 15.0507 35.5836 15.5361C35.5836 16.0215 35.1902 16.4151 34.7048 16.4151Z"
            fill="#60B7FF"/>
          <path
            d="M34.7048 20.6338H20.7168C20.2314 20.6338 19.8379 20.2403 19.8379 19.7549C19.8379 19.2695 20.2314 18.876 20.7168 18.876H34.7047C35.19 18.876 35.5836 19.2695 35.5836 19.7549C35.5836 20.2403 35.1902 20.6338 34.7048 20.6338Z"
            fill="#60B7FF"/>
          <path
            d="M34.7048 24.8525H20.7168C20.2314 24.8525 19.8379 24.459 19.8379 23.9736C19.8379 23.4882 20.2314 23.0947 20.7168 23.0947H34.7047C35.19 23.0947 35.5836 23.4882 35.5836 23.9736C35.5836 24.459 35.1902 24.8525 34.7048 24.8525Z"
            fill="#60B7FF"/>
          <path
            d="M43.8789 60.3652C50.2539 60.3652 55.4219 55.1973 55.4219 48.8223C55.4219 42.4473 50.2539 37.2793 43.8789 37.2793C37.5039 37.2793 32.3359 42.4473 32.3359 48.8223C32.3359 55.1973 37.5039 60.3652 43.8789 60.3652Z"
            fill="white"/>
          <path
            d="M39.1582 55.1648C37.2241 53.7215 35.9693 51.4157 35.9693 48.8223C35.9693 46.2288 37.2241 43.9231 39.1582 42.4797C40.7901 40.0043 43.3477 38.1929 46.3398 37.5431C45.5467 37.3708 44.7236 37.2793 43.8789 37.2793C37.5039 37.2793 32.3359 42.4473 32.3359 48.8223C32.3359 55.1973 37.5039 60.3652 43.8789 60.3652C44.7236 60.3652 45.5467 60.2737 46.3398 60.1014C43.3476 59.4515 40.7901 57.6403 39.1582 55.1648Z"
            fill="#F4F4F4"/>
          <path
            d="M44.0063 40.9121H43.8784C39.5171 40.9121 35.9688 44.4603 35.9688 48.8218C35.9688 53.1833 39.517 56.7315 43.8784 56.7315H47.7086C48.194 56.7315 48.5875 56.338 48.5875 55.8526C48.5875 55.3672 48.194 54.9737 47.7086 54.9737H43.8784C40.4863 54.9737 37.7266 52.214 37.7266 48.8218C37.7266 45.4296 40.4862 42.6699 43.8784 42.6699H44.0063C47.328 42.6699 50.0303 45.3723 50.0303 48.6939V49.9961C50.0303 50.7671 49.403 51.3943 48.632 51.3943H48.2088V45.3704C48.2088 44.885 47.8152 44.4915 47.3298 44.4915C46.8541 44.4915 46.4675 44.8699 46.4523 45.342C45.7322 44.808 44.8417 44.4915 43.8784 44.4915C41.4907 44.4915 39.5481 46.4341 39.5481 48.8218C39.5481 51.2095 41.4907 53.1521 43.8784 53.1521H48.632C50.3723 53.1521 51.7881 51.7364 51.7881 49.9961V48.6939C51.7881 44.4031 48.2972 40.9121 44.0063 40.9121ZM46.4509 51.3943H43.8784C42.46 51.3943 41.3059 50.2402 41.3059 48.8218C41.3059 47.4034 42.46 46.2493 43.8784 46.2493C45.2969 46.2493 46.4509 47.4034 46.4509 48.8218V51.3943Z"
            fill="url(#paint0_linear)"/>
          <defs>
            <linearGradient id="paint0_linear" x1="35.9688" y1="40.9121" x2="51.8586" y2="41.0563"
                            gradientUnits="userSpaceOnUse">
              <stop stopColor="#1557FF"/>
              <stop offset="1" stopColor="#47A9E1"/>
            </linearGradient>
          </defs>
        </svg>
        <div>
          <div className={ styles.title }>Будьте в курсе скидок и спецпредложений на тренировки!</div>
          <div className={ styles.terms }><Terms termHref={ termHref }/></div>
        </div>
      </div>
      <div className={ styles.container }>
        <div className={ styles.fieldContainer }>
          <Input type={ 'email' } checkLocalMask={ isSend }
                 keyPress={ onSubscribeSuccess } className={ styles.input }/>
        </div>
        <Btn className={ styles.btn } onClick={ clickHandler } title={ 'Подписаться' }/>
      </div>
    </div>
  )
}

const mapStateToProps = state => ({
  modalNames: state.modal.modalNames,
  windowInnerWidth: state.window.size.windowInnerWidth
})

const mapDispatchToProps = dispatch => ({
  openModal: (name) => dispatch(openModal(name))
})

export default connect(mapStateToProps, mapDispatchToProps)(Subscription)
