import { combineReducers } from "redux"
import club from './club/reducer'
import destination from './destination/reducer'
import comment from './comment/reducer'

export default combineReducers({
  club,
  destination,
  comment
})
