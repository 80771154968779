import { connect } from 'react-redux'
import { withRouter } from "next/router"
import { toggleMenuState } from "../../store/global/actions"
import ToggleLogo from '../../assets/icons/leftSideBar/toggle.svg'
import Auth from "./Auth/Auth"
import LinkList from "./LinkList/LinkList"
import Other from "./Other/Other"
import styles from './LeftSideBar.module.scss'
import Link from "next/link"
import { useEffect } from "react"

const LeftSideBar = ({
                       leftMenuIsOpen,
                       toggleMenuState,
                       windowInnerWidth,
                       leftSideBar,
                       Images,
                       otherLink,
                       logo,
                       authInfo,
                       signIn,
                       signOut,
                       onClickAuthAvatar,
                       onClickAuthName,
                       router
                     }) => {

  console.log({ router })

  const hideMenuHandler = ({ target }) => {
    console.log(target)
    const rootNode = document.querySelector('.' + styles.root)
    if (!rootNode.contains(target)) toggleMenuState({ status: false })
  }

  useEffect(() => {
    // document.body.addEventListener('onMouseOver', hideMenuHandler)
  }, [])

  const openMenu = ({ target }) => {
    let node = document.getElementById('left-sidebar-logo')
    if (target !== node && !node.contains(target)) toggleMenuState({ status: true })
  }

  const hideLogo = router?.pathname?.indexOf('/clubs') >= 0

  return (
    <div className={ styles.root + (leftMenuIsOpen ? ' ' + styles.isOpen : '') }
         onMouseLeave={ toggleMenuState.bind(null, { status: false }) }
         onMouseOver={ openMenu }
    >
      <div className={ styles.container }>
        <div className={ styles.toggle }>
          <ToggleLogo className={ styles.icon }/>
          <div className={ styles.logo + (hideLogo ? ' ' + styles.hideLogo : '') }>
            <Link href={ '/' }>
              <a id={ 'left-sidebar-logo' }>
                { logo }
              </a>
            </Link>
          </div>
        </div>
        <Auth authInfo={ authInfo }
              signIn={ signIn }
              onClickAuthName={ onClickAuthName }
              onClickAuthAvatar={ onClickAuthAvatar }
              signOut={ signOut }/>
        <LinkList
          authInfo={ authInfo } leftSideBar={ leftSideBar } Images={ Images }/>
        <Other list={ otherLink }/>
      </div>
    </div>
  )
}

const mapStateToProps = state => ({
  leftMenuIsOpen: state.global.leftMenuIsOpen,
  windowInnerWidth: state.window.size.windowInnerWidth
})

const mapDispatchToProps = dispatch => ({
  toggleMenuState: ({ status }) => dispatch(toggleMenuState({ status }))
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(LeftSideBar))
