import { combineReducers } from "redux"
import global from './global/reducer'
import differentClubs from './differentClubs/reducer'
import advice from './advice/reducer'

export default combineReducers({
  global,
  differentClubs,
  advice
})
