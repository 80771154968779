export const SET_DEFAULT_SETTINGS = 'SET_DEFAULT_SETTINGS'
export const TOGGLE_LEFT_MENU_STATE = 'TOGGLE_LEFT_MENU_STATE'


export const toggleMenuState = ({ status }) => (dispatch, getState) => {
  const { leftMenuIsOpen } = getState().global
  if (leftMenuIsOpen !== status)
    dispatch({ type: TOGGLE_LEFT_MENU_STATE, status: status })
}

const setSettingsDefault = ({ data }) => (dispatch, getState) => {
  dispatch({ type: SET_DEFAULT_SETTINGS, settings: data })
}
