import { createReducer } from "@/utils/store"

import { SET_PROMO_INFO, RESET_PROMO_INFO } from "@/store/promo/actions"

const initial = {
  text: '',
  type: ''
}

export default createReducer(initial, {
  [ SET_PROMO_INFO ]: (state, { data }) => ({ ...state, ...data }),
  [ RESET_PROMO_INFO ]: () => JSON.parse(JSON.stringify(initial)),
})
