import { createReducer } from "@/utils/store"
import {
  RESET_CLUBS_SLUG,
  SET_CLUBS_SLUG,
  CHANGE_CLUBS_FETCHING,
  RESET_CLUBS,
  RESET_CLUBS_ADDRESS_OBJECT,
  SET_CLUBS,
  SET_CLUBS_ADDRESS_OBJECT, SET_CLUBS_COUNT
} from "@/store/clubs/global/actions"

const initial = {
  slug: {
    service: null,
    option: null,
  },
  fetch: true,
  clubs: {},
  clubsCount: null,
  addressObject: {
    city_slug: '',
    subway_slug: ''
  },
}

export default createReducer(initial, {
  [ RESET_CLUBS_SLUG ]: (state) => ({ ...state, slug: JSON.parse(JSON.stringify(initial.slug)) }),
  [ SET_CLUBS_SLUG ]: (state, { data }) => ({ ...state, slug: { ...state.slug, ...data } }),
  [ CHANGE_CLUBS_FETCHING ]: (state, { status }) => ({ ...state, fetch: status }),
  [ RESET_CLUBS ]: (state) => ({
    ...state,
    clubs: JSON.parse(JSON.stringify(initial.clubs)),
    clubsCount: JSON.parse(JSON.stringify(initial.clubsCount)),
  }),
  [ SET_CLUBS_ADDRESS_OBJECT ]: (state, { addressObject }) => ({ ...state, addressObject }),
  [ RESET_CLUBS_ADDRESS_OBJECT ]: (state) => ({
    ...state,
    addressObject: JSON.parse(JSON.stringify(initial.addressObject))
  }),
  [ SET_CLUBS ]: (state, { clubs }) => ({ ...state, clubs }),
  [ SET_CLUBS_COUNT ]: (state, { clubsCount }) => ({ ...state, clubsCount }),
})
